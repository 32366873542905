import {react} from "react";
import css from "./hero.module.css"
import dataJson from "../../data.json"

const HeroSection = ({data}) =>{
    return<div className={css.container}>
        <div className={css.subContainer}>
            <img className={css.brandImage} src={dataJson.logo} width={"155"} height={"100"} alt="Brand Logo"/>
        </div>
        <div className={css.subContainer}>
            <span className={`${css.brandText} ${css.brandTextLeft}`}>{data.brandText1}</span>
            <span className={css.brandText}>{data.brandText2}</span>
        </div>
        <div className={css.subContainer}>
            <span className={css.subTitle}>{data.Subtitle}</span>
        </div>
        <div className={css.subContainer}>
            <img className={css.keyboard} src={data.image} alt="Arion keyboard" />
        </div>
    </div>
}

export default HeroSection