import React from 'react';
import css from './description.module.css';

const DescriptionCard = ({ section1, DescriptionText }) => {
    return (
        <div
            className={`${
                section1.direction == 'top' ? css.toptobottom : css.bottomtotop
            } ${css.parentContainer}`}
        >
            <div className={css.container}>
                {DescriptionText && (
                    <div className={css.Row}>
                        <span className={css.DescriptionText}>
                            {DescriptionText}
                        </span>
                    </div>
                )}
                <div className={css.Row}>
                    <div className={css.FrostSection}>
                        <img
                            src={section1.url}
                            className={css.FrostSectionImage}
                            alt="descriptionImage"
                        />
                        <div className={css.TextContainer}>
                            <span
                                className={css.TextTitleArion}
                                dangerouslySetInnerHTML={{
                                    __html: section1.title,
                                }}
                            />
                            <span
                                className={css.textSubtitleArion}
                                dangerouslySetInnerHTML={{
                                    __html: section1.content,
                                }}
                            />
                        </div>
                    </div>
                </div>
                <div className={css.RowCards}>
                    <div className={css.twoCards}>
                        {section1.card1 && (
                            <div className={css.cards}>
                                <img
                                    src={section1.card1.url}
                                    className={css.cardImage}
                                    alt="descriptionCardImage"
                                />
                                <span
                                    className={css.cardText}
                                    dangerouslySetInnerHTML={{
                                        __html: section1.card1.title,
                                    }}
                                />
                                <div className={css.cardOverlay}></div>
                            </div>
                        )}
                        {section1.card2 && (
                            <div className={css.cards}>
                                <img
                                    src={section1.card2.url}
                                    className={css.cardImage}
                                    alt="descriptionCardImage"
                                />
                                <div className={css.cardOverlay}></div>
                                <span
                                    className={css.cardText}
                                    dangerouslySetInnerHTML={{
                                        __html: section1.card2.title,
                                    }}
                                />
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DescriptionCard;
