import React from 'react';
import css from './multicolumn.module.css';

const MultiColumn = ({ colData }) => {
    return (
        <div className={css.multicolumnParent}>
            <div className={css.Container}>
                {colData &&
                    colData.map((e, i) => {
                        if (i < 6) {
                            return (
                                <div
                                    className={css.Column}
                                    key={i}
                                >
                                    <div className={css.colImage}>
                                        <img
                                            src={e.image}
                                            width={126}
                                            height={125}
                                            alt="Services Images"
                                        />
                                    </div>
                                    <div className={css.title}>{e.title}</div>
                                    <div
                                        className={css.description}
                                        dangerouslySetInnerHTML={{
                                            __html: e.subtitle,
                                        }}
                                    />
                                </div>
                            );
                        } else {
                            return <></>;
                        }
                    })}
            </div>
        </div>
    );
};

export default MultiColumn;
