import "./App.css";
import { DescriptionCard, HeroSection, MultiColumn, Gallery, Footer } from "./Component";
import FloatingCTA from "./Component/FloatingButton/Button";
import data from "./data.json";
function App() {
  return (
    <div className="meckeys-parent-container">
      <FloatingCTA data={data.FloatingCTA} />
      <HeroSection data={data.heroSection}/>
      <DescriptionCard
        section1={data.section1}
        DescriptionText={data.DescriptionText}
      />
      <MultiColumn colData={data.colData} />
      <DescriptionCard section1={data.section2} />
      <Gallery data={data.GalleryData} />
      <Footer footer={data.footer} />
    </div>
  );
}

export default App;
