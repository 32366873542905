import React from "react";
import css from "./gallery.module.css";

const Gallery = ({ data }) => {
  return (
    <div className={css.ParentContainer}>
      <div className={css.container}>
        {data.title && (
          <div
            className={css.title}
            dangerouslySetInnerHTML={{ __html: data.title }}
          />
        )}
        <div className={css.GalleryImageContainer}>
        <div className={css.GalleryImages}>
          <div className={css.topRow}>
            <div className={css.TopRow1}>
              <div className={css.overlay}></div>
              <img src={data.topRow.img1} />
              {data.topRow.img1Text && data.topRow.img1Text != "" && (
                <span className={css.ImageText}>{data.topRow.img1Text}</span>
              )}
            </div>
            <div className={css.TopRow1}>
              {/* <div className={css.overlay}></div> */}
              <img src={data.topRow.img2} />
              {data.topRow.img2Text && data.topRow.img2Text != "" && (
                <span className={css.ImageText}>{data.topRow.img2Text}</span>
              )}
            </div>
            <div className={css.TopRow2}>
              <div className={css.overlay}></div>
              <img src={data.topRow.img3} />
              {data.topRow.img3Text && data.topRow.img3Text != "" && (
                <span className={css.ImageText}>{data.topRow.img3Text}</span>
              )}
            </div>
            <div className={css.TopRow2}>
              {/* <div className={css.overlay}></div> */}
              <img src={data.topRow.img4} />
              {data.topRow.img4Text && data.topRow.img4Text != "" && (
                <span className={css.ImageText}>{data.topRow.img4Text}</span>
              )}
            </div>
          </div>

          <div className={css.middleRow}>
            <div className={css.middleLeftRow}>
              <div className={css.middleLeftRowTop}>
                <div className={css.imgMiddleRowLeft}>
                  {/* <div className={css.overlay}></div> */}
                  <div className={css.iconImageContainer}>
                    <img
                      src={data.middleRow.middleTop.middleTop1}
                      className={css.iconImage}
                    />
                    {data.middleRow.middleTop.middleTop1Text &&
                      data.middleRow.middleTop.middleTop1Text != "" && (
                        <span
                          className={css.iconImageText}
                          dangerouslySetInnerHTML={{
                            __html: data.middleRow.middleTop.middleTop1Text,
                          }}
                        />
                      )}
                  </div>
                </div>
                <div className={css.imgMiddleRowLeft}>
                  {/* <div className={css.overlay}></div> */}
                  <div className={css.iconImageContainer}>
                    <img
                      src={data.middleRow.middleTop.middleTop2}
                      className={css.iconImage}
                    />
                    {data.middleRow.middleTop.middleTop2Text &&
                      data.middleRow.middleTop.middleTop2Text != "" && (
                        <span
                          className={css.iconImageText}
                          dangerouslySetInnerHTML={{
                            __html: data.middleRow.middleTop.middleTop2Text,
                          }}
                        />
                      )}
                  </div>
                </div>
              </div>
              <div className={css.imgMiddleRowLeftBottom}>
                <div className={css.overlay}></div>
                <img src={data.middleRow.middleTop.leftMiddle} />
                {data.middleRow.middleTop.leftMiddleText &&
                      data.middleRow.middleTop.leftMiddleText != "" && (
                        <span
                          className={css.ImageText}
                          dangerouslySetInnerHTML={{
                            __html: data.middleRow.middleTop.leftMiddleText,
                          }}
                        />
                      )}
              </div>
            </div>
            <div className={css.middleRowCenter}>
              {/* <div className={css.overlay}></div> */}
              <img src={data.middleRow.center} />
            </div>
            <div className={css.middleRightRow}>
              <div className={css.middleRightTop}>
                <div className={css.overlay}></div>
                <img src={data.middleRow.middleRight.middleRightTop} />
                {data.middleRow.middleRight.middleRightTopText &&
                      data.middleRow.middleRight.middleRightTopText != "" && (
                        <span
                          className={css.ImageText}
                          dangerouslySetInnerHTML={{
                            __html: data.middleRow.middleRight.middleRightTopText,
                          }}
                        />
                      )}
              </div>
              <div className={css.middleRightBottom}>
                {/* <div className={css.overlay}></div> */}
                {/* <img src={data.middleRow.middleRight.middleRightbottom} /> */}
                {data.middleRow.middleRight.middleRightBottomText &&
                      data.middleRow.middleRight.middleRightBottomText != "" && (
                        <span
                          className={css.ImageTextGasket}
                          dangerouslySetInnerHTML={{
                            __html: data.middleRow.middleRight.middleRightBottomText,
                          }}
                        />
                      )}
              </div>
            </div>
          </div>

          <div className={css.bottomRow}>
            <div className={css.bottomRowImgLeft}>
              {/* <div className={css.overlay}></div> */}
              {/* <img src={data.bottomRow.img1} /> */}
              {data.bottomRow.img1Text &&
                      data.bottomRow.img1Text != "" && (
                        <span
                          className={css.ImageTextFrame}
                          dangerouslySetInnerHTML={{
                            __html: data.bottomRow.img1Text,
                          }}
                        />
                      )}
            </div>
            <div className={css.bottomRowImgMiddle}>
              {/* <div className={css.overlay}></div> */}
              <img src={data.bottomRow.img2} />
              {data.bottomRow.img2Text &&
                      data.bottomRow.img2Text != "" && (
                        <span
                          className={css.ImageText}
                          dangerouslySetInnerHTML={{
                            __html: data.bottomRow.img2Text,
                          }}
                        />
                      )}
            </div>
            <div className={css.bottomRowImgMiddle}>
              {/* <div className={css.overlay}></div> */}
              <img src={data.bottomRow.img3} />
              {data.bottomRow.img3Text &&
                      data.bottomRow.img3Text != "" && (
                        <span
                          className={css.ImageText}
                          dangerouslySetInnerHTML={{
                            __html: data.bottomRow.img3Text,
                          }}
                        />
                      )}
            </div>
            <div className={css.bottomRowImgMiddle}>
              {/* <div className={css.overlay}></div> */}
              <img src={data.bottomRow.img4} />
              {data.bottomRow.img4Text &&
                      data.bottomRow.img4Text != "" && (
                        <span
                          className={css.ImageText}
                          dangerouslySetInnerHTML={{
                            __html: data.bottomRow.img4Text,
                          }}
                        />
                      )}
            </div>
            <div className={css.bottomRowImgRight}>
              {/* <div className={css.overlay}></div> */}
              <img src={data.bottomRow.img5} />
            </div>
          </div>
        </div>
        </div>
        
      </div>
    </div>
  );
};

export default Gallery;
