import css from './footer.module.css';

const Footer = ({ footer }) => {
    return (
        <div className={css.parentContainer}>
            <div className={css.container}>
                <div className={css.backGround}>
                    <img
                        src="/HeroSectionKeyboard.webp"
                        alt="footerbg"
                        className={css.footerBGKeyboard}
                    />
                    <img
                        src="/Footer/USB.svg"
                        alt="footerbg"
                        className={css.footerBGUSB}
                    />
                    <div className={css.ThreeComp}>
                        <img
                            src="/Footer/KeyCapPuller.svg"
                            alt="footerbg"
                            className={css.footerBGKEYCAP}
                        />
                        <img
                            src="/Footer/ScrewDriver.svg"
                            alt="footerbg"
                            className={css.footerBGScrew}
                        />
                        <img
                            src="/Footer/KeySwitchPuller.svg"
                            alt="footerbg"
                            className={css.footerBG}
                        />
                    </div>
                </div>
                <div
                    className={css.title}
                    dangerouslySetInnerHTML={{ __html: footer.title }}
                />
                <div
                    className={css.subtitle}
                    dangerouslySetInnerHTML={{ __html: footer.subtitle }}
                />
                <div className={css.Specs}>
                    {footer.specs &&
                        footer.specs.map((e, i) => (
                            <div
                                className={css.ListSpecs}
                                dangerouslySetInnerHTML={{ __html: e }}
                                key={i}
                            />
                        ))}
                </div>
                <div
                    className={css.subtitle}
                    dangerouslySetInnerHTML={{ __html: footer.subtitle2 }}
                />
                <div
                    className={css.subtitleCont}
                    dangerouslySetInnerHTML={{ __html: footer.contSubTitle2 }}
                />
                <div className={css.TwoBox}>
                    <div className={css.box1}>
                        <div
                            className={css.boxTitle}
                            dangerouslySetInnerHTML={{ __html: footer.box1 }}
                        />
                        {footer.box1Cont &&
                            footer.box1Cont.map((e, i) => (
                                <div
                                    className={css.BoxContent}
                                    dangerouslySetInnerHTML={{ __html: e }}
                                    key={i}
                                />
                            ))}
                    </div>
                    <div className={css.box2}>
                        <div
                            className={css.boxTitle}
                            dangerouslySetInnerHTML={{ __html: footer.box2 }}
                        />
                        {footer.box2Cont &&
                            footer.box2Cont.map((e, i) => (
                                <div
                                    key={i}
                                    className={css.BoxContent}
                                    dangerouslySetInnerHTML={{ __html: e }}
                                />
                            ))}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Footer;
